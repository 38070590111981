import classNames from 'clsx';
import * as React from 'react';
import {
  customCssClasses,
  getDataAttributes,
} from '@wix/editor-elements-common-utils';
import { useInterval } from '@wix/thunderbolt-elements/src/providers/useInterval/useInterval';
import { useInViewport } from '@wix/thunderbolt-elements/src/providers/useInViewport/useInViewport';
import { useGesture } from '@wix/thunderbolt-elements/src/providers/useGesture/useGesture';
import {
  translations as translationKeys,
  TestIds,
  NO_TRANSITION,
} from '../constants';
import {
  ISlideShowContainerImperativeActions,
  SlideShowContainerProps,
  MoveToSlideArgs,
} from '../SlideShowContainer.types';
import slideshowSemanticClassNames from '../SlideShowContainer.semanticClassNames';
import stateBoxSemanticClassNames from '../../MultiStateBox/MultiStateBox.semanticClassNames';
import styles from './style/SlideShowContainer.scss';
import NavigationButtons from './NavigationButtons';
import DotsNavigationButtons from './NavigationDotButtons';
import Slides from './Slides';

const SlideShowContainer: React.ForwardRefRenderFunction<
  ISlideShowContainerImperativeActions,
  SlideShowContainerProps
> = (props, ref) => {
  const {
    id,
    className,
    customClassNames = [],
    skin,
    hasShadowLayer,
    translations,
    currentSlideIndex,
    slidesProps,
    showNavigationDots,
    showNavigationButton,
    autoPlay,
    shouldChangeSlidesOnSwipe = true,
    autoPlayInterval,
    pauseAutoPlayOnMouseOver,
    transition,
    transitionDuration,
    transitionReverse,
    changeSlide,
    reducedMotion,
    children,
    onCurrentSlideChanged,
    onChange,
    onMouseEnter,
    onMouseLeave,
    onClick,
    onDblClick,
    play,
    onPlay,
    pause,
    onPause,
    // defaults to canAutoPlay logic for initial value
    isPlaying = autoPlay &&
      React.Children.toArray(children()).length > 1 &&
      !reducedMotion,
    dynamicSlidesHeight = false,
    observeChildListChange,
  } = props;
  const [reverseByNavigation, setReverseByNavigation] = React.useState(false);
  const [inTransition, setInTransition] = React.useState(false);
  const slidesWrapperRef = React.useRef<HTMLDivElement>(null);

  const hasTransition = !reducedMotion && transition !== NO_TRANSITION;
  const setBackwardDirection = () => setReverseByNavigation(true);
  const setForwardDirection = () => setReverseByNavigation(false);
  const reverse = transitionReverse
    ? !reverseByNavigation
    : reverseByNavigation;

  const childrenArray = React.Children.toArray(children());
  const canAutoPlay = autoPlay && childrenArray.length > 1 && !reducedMotion;
  const moveToSlideCallbackRef = React.useRef<
    MoveToSlideArgs['callback'] | null
  >(null);

  const moveToSlide = React.useCallback(
    ({ slideIndex, isBackward, callback }: MoveToSlideArgs) => {
      if (inTransition || slideIndex === currentSlideIndex) {
        if (typeof callback === 'function') {
          callback();
        }
        return;
      }
      if (hasTransition && typeof callback === 'function') {
        moveToSlideCallbackRef.current = callback;
      }
      if (hasTransition) {
        setInTransition(true);

        const isNavigationByDotButton = isBackward === undefined;
        const isBackwardDirection = isNavigationByDotButton
          ? slideIndex < currentSlideIndex
          : isBackward;
        if (isBackwardDirection) {
          setBackwardDirection();
        } else {
          setForwardDirection();
        }
      }

      changeSlide(slideIndex);

      onChange?.({ type: 'change' } as React.ChangeEvent);

      // if we have no transition animation on slide change, trigger slide
      // changed instantly
      if (!hasTransition) {
        onCurrentSlideChanged?.(slideIndex);
        if (typeof callback === 'function') {
          callback();
        }
      }
    },
    [
      changeSlide,
      currentSlideIndex,
      hasTransition,
      inTransition,
      onChange,
      onCurrentSlideChanged,
    ],
  );

  const moveToNextSlide = React.useCallback(
    (callback: MoveToSlideArgs['callback'] = undefined) => {
      const newSlideIndex =
        currentSlideIndex === childrenArray.length - 1
          ? 0
          : currentSlideIndex + 1;

      return moveToSlide({
        slideIndex: newSlideIndex,
        isBackward: false,
        callback,
      });
    },
    [childrenArray.length, currentSlideIndex, moveToSlide],
  );

  const moveToPrevSlide = React.useCallback(
    (callback: MoveToSlideArgs['callback'] = undefined) => {
      const newSlideIndex =
        currentSlideIndex === 0
          ? childrenArray.length - 1
          : currentSlideIndex - 1;

      return moveToSlide({
        slideIndex: newSlideIndex,
        isBackward: true,
        callback,
      });
    },
    [childrenArray.length, currentSlideIndex, moveToSlide],
  );

  const _onMouseEnter =
    canAutoPlay && pauseAutoPlayOnMouseOver
      ? (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          pause();
          onMouseEnter?.(event);
        }
      : onMouseEnter;

  const _onMouseLeave =
    canAutoPlay && pauseAutoPlayOnMouseOver
      ? (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          play();
          onMouseLeave?.(event);
        }
      : onMouseLeave;
  const focusListeners = canAutoPlay
    ? {
        onFocus: () => pause(),
        onBlur: () => play(),
      }
    : {};

  const slideShowRef = React.useRef<HTMLDivElement>(null);
  const isSlideShowInViewport = useInViewport(slideShowRef);
  useGesture(
    'onSwipeLeft',
    () => shouldChangeSlidesOnSwipe && moveToNextSlide(),
    slideShowRef,
  );
  useGesture(
    'onSwipeRight',
    () => shouldChangeSlidesOnSwipe && moveToPrevSlide(),
    slideShowRef,
  );

  useInterval(
    moveToNextSlide,
    isPlaying && isSlideShowInViewport ? autoPlayInterval : null,
  );

  const getCustomMeasures = React.useCallback(() => {
    return {
      height: {
        [id]: () => {
          const slideComp = document.getElementById(id);
          if (slideComp) {
            return slideComp.clientHeight;
          }
          return 0;
        },
      },
    };
  }, [id]);

  React.useImperativeHandle(
    ref,
    () => {
      return {
        play: () => {
          play();
          onPlay?.({ type: 'autoplayOn' });
        },
        pause: () => {
          pause();
          onPause?.({ type: 'autoplayOff' });
        },
        moveToSlide,
        next: moveToNextSlide,
        previous: moveToPrevSlide,
        getCustomMeasures,
      };
    },
    [
      moveToNextSlide,
      moveToPrevSlide,
      moveToSlide,
      getCustomMeasures,
      onPause,
      onPlay,
      pause,
      play,
    ],
  );

  const isMultiStateBox = skin === 'StateBoxSkin';
  const rootSemanticClassNames = isMultiStateBox
    ? stateBoxSemanticClassNames.root
    : slideshowSemanticClassNames.root;

  React.useEffect(() => {
    if (observeChildListChange && slidesWrapperRef?.current) {
      observeChildListChange(id, slidesWrapperRef.current as HTMLElement);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      ref={slideShowRef}
      className={classNames(
        className,
        styles.slideShowContainer,
        styles[skin],
        'ignore-focus',
        customCssClasses(rootSemanticClassNames, ...customClassNames),
      )}
      role="region"
      tabIndex={-1}
      aria-label={
        translations.slideShowAriaLabel ||
        translationKeys.SLIDE_SHOW_ARIA_LABEL_DEFAULT
      }
      onClick={onClick}
      onDoubleClick={onDblClick}
      onMouseEnter={_onMouseEnter}
      onMouseLeave={_onMouseLeave}
      {...focusListeners}
    >
      {showNavigationButton && (
        <NavigationButtons
          skin={skin}
          translations={translations}
          moveToNextSlide={moveToNextSlide}
          moveToPrevSlide={moveToPrevSlide}
        />
      )}
      {hasShadowLayer && (
        <div data-testid={TestIds.shadowLayer} className={styles.shadowLayer} />
      )}
      <Slides
        ref={slidesWrapperRef}
        isPlaying={isPlaying}
        isSlideShowInViewport={isSlideShowInViewport}
        reverse={reverse}
        transition={reducedMotion ? NO_TRANSITION : transition}
        transitionDuration={transitionDuration}
        currentSlideIndex={currentSlideIndex}
        inTransition={inTransition}
        onSlideEntered={() => {
          if (!hasTransition) {
            return;
          }
          setInTransition(false);
        }}
        onSlideExited={() => {
          // trigger slide changed handlers at the end of the transition
          onCurrentSlideChanged?.(currentSlideIndex);
          if (moveToSlideCallbackRef.current) {
            moveToSlideCallbackRef.current();
            moveToSlideCallbackRef.current = null;
          }
        }}
        dynamicHeight={dynamicSlidesHeight}
      >
        {childrenArray[currentSlideIndex]}
      </Slides>

      {showNavigationDots && (
        <DotsNavigationButtons
          focusSlideShow={() => slideShowRef.current?.focus()}
          translations={translations}
          slidesProps={slidesProps}
          currentSlideIndex={currentSlideIndex}
          changeSlide={slideIndex => {
            moveToSlide({ slideIndex });
          }}
        />
      )}
    </div>
  );
};

export default React.forwardRef(SlideShowContainer);
